import styled from "styled-components";



export const Container = styled.button`
  background-color: #ee0b19;
  width: 96px;
  height: 64px;
  border-radius: 8px;
  border: solid 1px #ee0b19;
  color: #fff;
  cursor: pointer;

  font-size: 14px;
  font-weight: 400;

  &:hover {
    background-color: #c71b1b;
    color: #fff;
  }

  &:active {
    background-color: #921212;
    color: #fff;
  }


`;