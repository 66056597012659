import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from '../pages/Home';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Log from '../pages/Log';
import PrivateRouter from '../components/PrivateRouter/index';

function AppRouter(){

    return(
        <Router>
            <Routes>
                <Route path="/Login" element={<Login/>} />
                {/* <Route path="/register" element={<Register/>} /> */}

                <Route path="/" element={<PrivateRouter/>} >
                    <Route path="/" element={<Home />} />
                    <Route path="/logger/id/:id" element={<Log />} />
                </Route>  
            </Routes>
        </Router>
    )
}

export default AppRouter