import React, { useContext, useReducer } from "react";

const initialState = {
  email: null,
  token: null,
  authorized: false,
  name: null
}

const reducer = (state, action) => {

  switch (action.type) {
    case ("Login"):
      localStorage.setItem("email", action.payload.email)
      localStorage.setItem("token", action.payload.token)
      localStorage.setItem("name", action.payload.name)
      return {
        email: action.payload.email,
        token: action.payload.token,
        authorized: true,
        name: action.payload.name
      }
    case ("Logout"):  
      localStorage.clear()
      return {
        authorized: false,
        email: null
      }
    default:
      return state;
  }
}

const Context = React.createContext(initialState)

export const Provider = ({ children }) => {

  const auth = useContext(Context)

  const localUser = localStorage.getItem("email");
  const localToken = localStorage.getItem("token");
  const localName = localStorage.getItem("name");

  auth.email = localUser;
  auth.token = localToken;
  auth.authorized = !!localUser;
  auth.name = localName;

  const [state, dispatch] = useReducer(reducer, auth)

  return (
    <Context.Provider value={{ state, dispatch  }}>
      {children}
    </Context.Provider>
  )
}

export default Context;