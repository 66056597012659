/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { db } from "../../services/api"
import Context from "../../context/AuthContext";
import AlertMessage from "../../components/AlertMessage";
import "./Login.scss"

const Login = () => {

const [ open, setOpen ] = useState(false);
const [ email, setEmail ] = useState()
const [ password, setPassword ] = useState()
const [ error, setError ] = useState(null)

const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
    
    const auth = useContext(Context)
    const navigate = useNavigate()
    
     useEffect( () => {

        async function authUseEffect(){
            if(auth.state.authorized){
                const token = localStorage.getItem("token")
    
                await fetch(`${process.env.REACT_APP_URL}/api/v1/user/verify`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                }})
                .then(res => res.json())
                .then((res) => {
                    console.log(res.success)
                    if(res.success === true){
                        navigate("/")
                    }
                })
            }
        }
        authUseEffect()
    },[auth])
    
    const payload = {
        "email":email, 
        "password":password
    }
    
    async function handleSubmit(e){
        e.preventDefault()

        await fetch(`${process.env.REACT_APP_URL}/api/v1/user/login`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: new Headers({
                "Content-Type": "Application/json"
            })
        })
        .then(res => res.json())
        .then( data => {
            if(Object.keys(data).includes("error")){
                setError(data.error)
                setOpen(true)
                return;
            } else {
                auth.dispatch({ type: "Login", payload: { email: data.email, token: data.token, name: data.first_name}})
                navigate("/")
            }
        })
    }
    return (
        <div className="container">
            <main className="main-content">
                <img 
                className="main-content__logo" 
                src="image/logo.jpg" 
                alt="logo médico sem fronteira"
                />
                <form onSubmit={handleSubmit}  className="form">
                <input 
                type="email"
                onChange={e => setEmail(e.target.value)} 
                placeholder="Email" 
                className="input"></input>
                <input 
                type="password"
                onChange={e => setPassword(e.target.value)}
                placeholder="Password" 
                className="input"></input>
                <button type="submit"  className='button'> Acessar  </button>
                <AlertMessage message={error} status={'error'} open={open}  onClick={handleClose} close={handleClose}/>
            </form>
            </main>
            {/* <span className="register">Não é cadastrado ainda? <a  href="/register">Cadastrar</a></span> */}
        </div>
    )
};

export default Login;