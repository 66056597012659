import React from 'react';
import "./User.scss";

const User = () => {

    const user = localStorage.getItem("name")
    const nameUpper = user[0].toUpperCase() + user.slice(1)
    
    return (
        <div className="box-name">
           {nameUpper}
        </div>        
    )
}

export default User;