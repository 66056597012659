import { useContext } from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import Context from "../../context/AuthContext"

const PrivateRouter = () => {
    
    const auth = useContext(Context)

    const location = useLocation()
   
    if(!auth.state.authorized){
        return <Navigate to="/login" state={{ from: location }} replace={true} />
    }
    return <Outlet />
}

export default PrivateRouter