import React, { useEffect, useContext } from "react";
import { db } from "../../services/api"
import Context from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { Table } from "../../components/Table/index";
import "./Home.scss";


const Home = () => {
    const auth = useContext(Context)
    const navigate = useNavigate();

   
    useEffect(()=>{

        if(auth.state.authorized){
            const token = localStorage.getItem("token")

            if(token === "undefined"){
                localStorage.clear()
                navigate("/login")
                
            } else {
                db.post("/api/v1/user/verify", {}, {
                    headers: {  
                        'Authorization': `Bearer ${token}`
                    }
                }).then((res) => {
                    if(res.status === 200) {
                        navigate("/")
                    } else {
                        navigate("/login")
                    }
                }).catch(error => {
                        if( error.response.status === 401) {
                            localStorage.clear()
                            navigate("/login")
                        }
                    }
                )
            }
        }

    }, [])

    return (
        <div className="container-log">
           <Table />
        </div>
    )
}

export default Home