import React from 'react';
import ReactDOM from 'react-dom/client';
import { DateProvider } from './components/inputs/DateInput';
import { Provider } from './context/AuthContext';
import AppRouter from './routes/App';
import './style/global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
    <Provider>
      <DateProvider>
       <AppRouter />
      </DateProvider>
    </Provider>
  // {/* </React.StrictMode>, */}
);
