const cpfMask = cpf => {
  return cpf.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}


const cnpjMask = cnpj => {
  return cnpj.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1.$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}


export const documentMask = document => {
  return document.replace(/\D/gm, '').length <= 11 ? cpfMask(document)  : cnpjMask(document)
};
