import { red } from '@mui/material/colors'
import React from 'react'
import Select from 'react-select'

const options = [
  { value: 'debitcard', label: 'Débito em conta corrente' },
  { value: 'creditcard', label: 'Cartão de crédito' },
  { value: 'invoice', label: 'Boleto' },
  { value: 'paypal', label: 'Paypal' },
  { value: 'pix', label: 'PIX'},
  { value: 'all', label: 'Todos os tipos de pagamento' },
  
]

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "25vw",
    height: 54,
    backgroundColor: '#fff',
    border: state.isFocused ? 'none' : 'solid 1px rgba(128, 128, 128, 0.389)',
    borderRadius: 5,
    outline: "none"
  })
}

const Payment = ({ handlePayment }) => {

  return (
    <Select options={options} 
      styles={customStyles} 
      defaultValue={{ 
        label: "Cartão de crédito", 
        value: "creditcard" 
      }} 
      onChange={handlePayment} 
    />
  )
}

export default Payment
