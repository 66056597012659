import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactJson from 'react-json-view'

import {IoMdArrowBack} from 'react-icons/io';

import {Container, Navigation, Payload} from './style'
import './Log.scss'



function Log() {

    const [logDetail, setLogDetail] = useState({});
    const { id } = useParams();

        async function getDetail() {
            await fetch(`${process.env.REACT_APP_URL}/api/v1/logger/id/${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(res => res.json() )
            .then((res) => {
                setLogDetail(res);
            })
        }

    useEffect(() => {
        getDetail();
    }, []);

    return (
        <Container>
            <Navigation>
                <Link to={"/"} >
                    <IoMdArrowBack />
                </Link>
            </Navigation>
            
            <Payload>
                    <ReactJson 
                        src={logDetail}
                        theme={"summerfruit:inverted"}  
                        displayDataTypes={false}
                        displayObjectSize={false}
                        collapsed={2}
                        name={"paymentBody"}
                     />
             </Payload>
        </Container>
    );
}

export default Log;

