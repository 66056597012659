import styled from "styled-components";



export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label``;

export const DateSelector = styled.input`
  height: 24px;
  width: 155px;
  border-radius: 5px;
  border: solid 1px rgba(128, 128, 128, 0.389);
  text-align: center;
  padding: 3px 5px;
  cursor: pointer;
  margin: 3px 0;
`;
