import styled, { css } from 'styled-components';

export const LoaderContainer = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  // width: 232px;
  // height: 120px;
  // border-radius: 8px 8px 0 0;
  // background-color: #fff;

  // padding: 0 16px;


  // position: absolute;
  // bottom: 64px;
  // right: 56px;  
  // box-shadow: 4px 1px 10px 1px #00000020;

  display: flex;
    justify-content: center;
    align-items: center;
    background: #b1b1b1b8;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    flex-direction: column;


  ${props => props.activeDownload ? css`
    opacity: 1;
    transition: opacity 0.6s linear;
    visibility: show;
  ` : css`
      opacity: 0; 
     transition: opacity 0.6s linear;
     visibility: hidden;
  `}
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: ${props => `conic-gradient(#c71b1b ${props.percentage * 3.6}deg, #EDEDED 0deg)`};
`;

export const Percentage = styled.span`

  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  padding-left: 8px;
  font-size: 14px;
  font-weight: bold; 
`;

export const Message = styled.p`
font-size: 19px;
padding: 15px 0;
font-weight: 500;
color: #5a554e;
  ${props => props.message.toLowerCase() === "gerando o arquivo" && css`
  
  animation: opacity-animation 2s ease-in-out infinite;
  @keyframes opacity-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  `}
`;

