import React from 'react';
import { Container } from "./style";



const GenericButton = ({clickFunction, buttonText}) => {
  return (
    <Container onClick={clickFunction}>{buttonText}</Container>
  );
}

export default GenericButton;