import React, { useEffect, useState } from 'react';
import { LoaderContainer, Loader, Percentage, Message } from './styles';

import socket from '../../services/socket'

const DownloadLoader = ({downloading}) => {
  const [percentageValue, setPercentageValue] = useState(0);
  const [message, setMessage] = useState('Baixando o arquivo');
  

  useEffect(() => {
    socket.on("percentage", msg => {
      setMessage(msg.message);
      setPercentageValue(msg.percentage)

      if (msg.percentage === 100) {
        setTimeout(() => {
          setPercentageValue(0);
          setMessage("Baixando o arquivo")
        }, 3000)
      }
  });

  }, [])
  


  return (

    <LoaderContainer activeDownload={downloading}>
      <Loader percentage={percentageValue}>
        <Percentage >{percentageValue}%</Percentage>
      </Loader>
      <Message message={message}>{message}</Message>
    </LoaderContainer>

  );
};

export default React.memo(DownloadLoader);