import styled from "styled-components";

export const CheckboxInput = styled.input`
  accent-color: #c71b1b;

  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
 `;


export const CheckboxWrap = styled.div`
  width: 200px;
  display: flex;
  align-items:center;
  justify-content: space-between;
  
  `;
  
  export const Label = styled.label`
  text-align: center;
  font-weight: bold;
`;