import React, { useContext, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { BiLogOut } from "react-icons/bi";

import Context from "../../../context/AuthContext";

const LogoutButton = () => {
    const auth = useContext(Context);
    const navigate = useNavigate();
    
    const handleExit = useCallback(() => {
        auth.authorized = false;
        localStorage.clear();
        navigate("/login");
    }, [auth, navigate])

    return (
        <BiLogOut title="Sair" className="icon-exit" onClick={handleExit}/>
    )
}

export default LogoutButton