import React, { useState, useEffect } from "react"
import { RiFilterOffLine } from "react-icons/ri";
import { BsEyeFill } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";
import User from "../User"
import Payment from "../Payment"
import ReactPaginate from "react-paginate";
import moment from "moment"
import momenttz from 'moment-timezone';
import "./Table.scss"
import DateInput, { DateContext } from "../inputs/DateInput";
import { Button, Container, ContainerDate, ContainerInput, DateFilter, DocumentContainer, DocumentFilter, TableBody, TableData, TableHead, TableHeadData, TableHeader, TableLog, TableRow, Title } from "./styles";
import GenericButton from "../buttons/GenericButton";
import Account from "../Account";
import { Link } from "react-router-dom";
import formatCurrency from "../../utils/formatCurrency";
import translatePaymentMethod from "../../utils/translatePaymentMethod";
import addMaskToDocument from "../../utils/addMaskToDocument";
import { useCallback } from "react";
import { LoadingIcon } from "../LoadingIcon";
import { Checkbox } from "../Checkbox";
import DownloadLoader from "../DownloadLoader";
import { documentMask } from "../../utils/inputMask";

export const Table = () => {

    const [dateStart, setDateStart] = useState("2021-01-11T00:00")
    const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DDTHH:mm"))
    const [paymentOption, setPaymentOption] = useState("creditcard")
    const [itemsList, setItemsList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [noPageFilter, setNoPageFilter] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [countRender, setCountRender] = useState(0);

    const [documentFilter, setDocumentFilter] = useState(null);

    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const itemListArr = []
    const url = `${process.env.REACT_APP_URL}/api/v1/logger`

    useEffect(() => {
        const utcEndDate = momenttz.tz(new Date(), 'America/Sao_Paulo');

        setLoading(true);

        fetch(`${url}/?paymentMethod=${paymentOption}${noPageFilter ? '' : '&page=' + currentPage}&startDate=${moment("2021-01-11T00:00").format()}&endDate=${moment(utcEndDate).format()}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then((res) => res.json()
            ).then(data => {

                data.payments.forEach(obj => {
                    itemListArr.push(obj)
                })
                setCurrentPage(data.currentPage)
                setTotalPages(data.totalPages)
                setItemsList(() => {
                    return [...itemListArr]
                });
                setLoading(false)
            })
    }, [paymentOption])

    const verifyDate = useCallback(() => {
        if (moment(dateStart).isAfter(dateEnd)) {
            restartDateFunction()
        } else {
            setDateStart(moment(dateStart).format())
            setDateEnd(moment(dateEnd).format())
        }


        setCountRender(oldCountRender => {
            return [oldCountRender + 1]
        });
    }, [dateEnd, dateStart])

    const handleSearch = useCallback((dateStartP, dateEndP, nextPage = 1) => {
        setLoading(true);

        const utcStartDate = momenttz.tz(dateStartP, 'America/Sao_Paulo');
        const utcEndDate = momenttz.tz(dateEndP, 'America/Sao_Paulo');

        setDateStart(moment(utcStartDate).format());
        setDateEnd(moment(utcEndDate).format());

        verifyDate();
        fetch(`${url}/?paymentMethod=${paymentOption}${noPageFilter ? '' : '&page=' + nextPage || currentPage}&startDate=${moment(utcStartDate).format()}&endDate=${moment(utcEndDate).format()}${documentFilter ? '&document=' + documentFilter : ''}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then((res) => res.json()
            ).then(data => {

                data.payments.forEach(obj => {
                    itemListArr.push(obj)
                })
                setCurrentPage(data.currentPage)
                setTotalPages(data.totalPages)
                setItemsList(() => {
                    return [...itemListArr]
                });
                setLoading(false);

            })
    }, [itemListArr, url, verifyDate, currentPage, paymentOption, noPageFilter])


    function handleDownload(dateStart, dateEnd, isNoPageFilter = false) {
        const allLogs = JSON.parse(document.querySelector(`#baixar-todas-as-paginas`)?.value)
        const utcStartDate = momenttz.tz(dateStartP, 'America/Sao_Paulo');
        const utcEndDate = momenttz.tz(dateEndP, 'America/Sao_Paulo');
        !!allLogs && setDownloading(true);

        fetch(`${url}/download/?paymentMethod=${paymentOption}${noPageFilter || allLogs ? '' : '&page=' + currentPage + '&size=150'}&startDate=${moment(utcStartDate).format()}&endDate=${moment(utcEndDate).format()}${documentFilter ? '&document=' + documentFilter : ''}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then((res) => res.blob())
            .then(res => {
                const urlBlob = window.URL.createObjectURL(res)
                const a = document.createElement("a")
                a.href = urlBlob
                a.download = "payments-logs.xlsx"
                document.body.appendChild(a)
                a.click()
                a.remove()
                setDownloading(false);

            })
    }

    const handlePayment = (option) => {
        setCurrentPage(oldCurrentPage => {
            return [1]
        });
        setPaymentOption(option.value)
    }

    function restartDateFunction() {
        setDateStart("2021-01-11T00:00")
        setDateEnd(moment().format("YYYY-MM-DDTHH:mm"))

        setCountRender(oldCountRender => {
            return [oldCountRender + 1]
        });
    }


    const [columnKeys, columnValues] = (() => {
        const columns = {
            "fullBody": "Body completo",
            "date": "Data",
            "document": "CPF/CNPJ",
            "logged": "Logado",
            "frequency": "Frequência",
            "campaignId": "CampaignId",
            "selectedChannel": "Canal Selecionado",
            "totalValue": "Valor Total",
            "vindiStatus": "Vindi Status",
            "donationType": "Tipo de doação",
            "paymentType": "Tipo de pagamento"
        }

        return [Object.keys(columns), Object.values(columns)]
    })()

    const { dateStartP, dateEndP } = React.useContext(DateContext);

    const handlePageClick = useCallback((event, isNoPageFilter = false) => {
        let page = 0;

        if (isNoPageFilter) {
            setNoPageFilter(true);
            handleSearch(dateStartP, dateEndP, page);
            return;
        }


        page = event.selected + 1;
        setNoPageFilter(false);
        handleSearch(dateStartP, dateEndP, page);
        setCurrentPage(page);
    }, [dateStartP, dateEndP, handleSearch]);

    
    const handleDocumentFilter = useCallback((document) => {
        const currentText = documentMask(document);
        setDocumentFilter(currentText);
    } , []);

    const MAX_DOCUMENT_LENGTH = 18;

    return (
        <Container>
            <TableHeader>
                <DateFilter>
                    <RiFilterOffLine title="tirar filtro" onClick={restartDateFunction} />
                    <ContainerInput>
                    <ContainerDate>
                        <DateInput label={'Data Inicial'} />
                        <DateInput label={'Data Final'} startInput={false} />
                    </ContainerDate>
                    <DocumentContainer>
                            <Title>Documento</Title>
                            <DocumentFilter 
                                onChange={(e) => handleDocumentFilter(e.target.value)}
                                value={documentFilter}
                                maxLength={MAX_DOCUMENT_LENGTH}
                                placeholder={'CPF/CNPJ'}
                            />
                        </DocumentContainer>
                    </ContainerInput>


                    <GenericButton clickFunction={() => handleSearch(dateStartP, dateEndP)} buttonText={"Buscar"} />
                </DateFilter>

                <Payment handlePayment={handlePayment} />
                <Checkbox label={'Baixar Todas as Páginas'} />
                <FaFileDownload id="btn" className="downloadBtn" title="baixar planilha" onClick={() => handleDownload(dateStartP, dateEndP)} />

                <Account />
            </TableHeader>

            <TableLog>
                <TableHead>
                    <TableRow>
                        {
                            columnKeys.map((column, i) => (<TableHeadData className={column}>{columnValues[i]}</TableHeadData>))
                        }
                    </TableRow>
                </TableHead>


                {loading ? (
                    <LoadingIcon />
                ) : (
                    <TableBody>
                        {
                            itemsList.map((log, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableData className="fullBody">
                                            <Link to={`/logger/id/${log.id}`}>
                                                <BsEyeFill />
                                            </Link>
                                        </TableData>

                                        <TableData className="date">{moment(log.createdAt).format("DD/MM/YYYY HH:mm")}</TableData>

                                        <TableData className="document">{addMaskToDocument(log.body.customer.document)}</TableData>
                                        <TableData className="logged">{log.body.logged ? "Sim" : "Não"}</TableData>
                                        <TableData className="frequency">{log.body.frequency === "unique" ? "Única" : "Mensal"}</TableData>
                                        <TableData className="campaignId">{log.body.CampaignId}</TableData>
                                        <TableData className="selectedChannel">{log.body.customer.channel_selected}</TableData>
                                        <TableData className="totalValue">{formatCurrency(log.body.orderTotal)}</TableData>
                                        <TableData className="vindiStatus">{String(log.vindi_status < 400)}</TableData>
                                        <TableData className="donationType">{log.body.type_donation}</TableData>
                                        <TableData className="paymentType" >{translatePaymentMethod(log.payment_method)}</TableData>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                )}



            </TableLog>

            <DownloadLoader downloading={downloading} />
            <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                breakClassName={"page-item"}
                pageClassName={"ul-link"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item disabled"}
                nextClassName={"page-item disabled"}
            />
        </Container>

    )
}
