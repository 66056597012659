import React, { useState } from 'react';
import { CheckboxInput, CheckboxWrap, Label } from './styles';

export const Checkbox= ({ label }) => {
  const [checked, setChecked] = useState(false);

  const handleChangeCheck = () => {
    setChecked(!checked);
  };

  const labelFormatted = label.toLowerCase().replace(/\s/gm,'-').normalize("NFD").replace(/[\u0300-\u036f]/g, "")

  return (<CheckboxWrap>
      <CheckboxInput
        id={labelFormatted}
        name={labelFormatted}
        type="checkbox"
        value={JSON.stringify(checked)}
        onChange={handleChangeCheck}
      />
      <Label for={labelFormatted}>{label}</Label>    
      </CheckboxWrap>
  );
};
