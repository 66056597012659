import styled from "styled-components";




export const Container = styled.div`
  width: 100%;
  height: fit-content;
  
  display: flex;
  flex-direction: column;
`;


export const Navigation = styled.nav`
  width: 100%;
  
  margin-top: 24px;
  
  padding: 0 32px ;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    font-size: 48px;
  }
`;


export const Payload = styled.div`
  width: 100%;
  padding: 0 32px ;

  font-size: 18px;
`;