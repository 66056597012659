import React from 'react';
import LogoutButton from "../buttons/LogoutButton";
import User from "../User";
import { Container } from "./style";

const Account = () => {
  return (
    <Container>
      <User/>
      <LogoutButton></LogoutButton>
    </Container>
  )
}

export default Account;
