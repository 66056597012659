import * as RC from 'react';
import React from 'react'
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import style from "./Alerts.scss"

export default function AlertMessage ({message, status, open, close }) {

    return (
        <Snackbar style={style}  open={open} autoHideDuration={2000} onClose={close}>
            <Alert style={style} severity={status} sx={{ width: '100%' }} onClose={close}>
                {message}
            </Alert>
        </Snackbar>
      );
}
