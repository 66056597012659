const paymentMethods = {
  CREDITCARD: "CREDITCARD",
  DEBITCARD: "DEBITCARD",
  INVOICE: "INVOICE",
  PAYPAL: "PAYPAL",
  PIX: "PIX",
};

const translatedPaymentMethods = {
  CREDITCARD: "Cartão de Crédito",
  DEBITCARD: "Débito",
  INVOICE: "Boleto",
  PAYPAL: "PayPal",
  PIX: "PIX",
}


const translatePaymentMethod = (paymentMethod) => {
  const upper = paymentMethod.toUpperCase();

  if (!paymentMethods[upper]) return paymentMethod;

  return translatedPaymentMethods[upper];
}

export default translatePaymentMethod;