import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  right: 0;
`;


export const TableHeader = styled.header`
  width: 90vw;
  height: 17%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DateFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 32%;

  svg {
    font-size: 32px;
  }
`;

export const ContainerInput = styled.div`
  width: 60%;

  display:flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ContainerDate = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.label``;

export const DocumentFilter = styled.input`
  height: 24px;
  width: 155px;
  border-radius: 5px;
  border: solid 1px rgba(128, 128, 128, 0.389);
  text-align: left;
  padding: 4px 10px;
  cursor: pointer;
  margin: 3px 0;
`;

export const TableLog = styled.table`
  width: 90vw;
  height: 70vh;
  box-sizing: border-box;
  border-collapse: collapse;
  box-shadow: 0px 0px 5px 1px gray;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 10px;

  position: relative;

  td, th {
    width: 100%;
    padding: 0 10px;

    &.fullBody{
      max-width: 100px;
      min-width: 100px;
      width: 100px;
    }

    &.date{
      max-width: 110px;
      min-width: 110px;
      width: 110px;
    }

    &.document{
      max-width: 180px;
      min-width: 180px;
      width: 180px;
    }

    &.logged{
      max-width:80px;
      min-width:80px;
      width:80px;
    }

    &.frequency{
      max-width: 90px;
      min-width: 90px;
      width:  90px;
    }

    &.campaignId{
      max-width:200px;
      min-width:200px;
      width:200px;
      overflow-wrap: break-word;  
      word-wrap: break-word; 
      word-break: break-word;
    }

    &.selectedChannel{
      max-width:250px;
      min-width:250px;
      width:250px;
    }

    &.totalValue{
      max-width:110px;
      min-width:110px;
      width:110px;
    }

    &.vindiStatus{
      max-width: 70px;
      min-width: 70px;
      width: 70px;
    }

    &.donationType{
      max-width:110px;
      min-width:110px;
      width:110px;
    }

    &.paymentType{
      max-width: 170px;
      min-width: 170px;
      width: 170px;
    }
  }
`;

export const TableHead = styled.thead`
  background-color: #c71b1b;
  font-weight: 500;
  height: 80px;
`;

export const TableHeadData = styled.th`
  color: #fff;
  font-size: 16px;
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  tbody > & {
    height: 64px;
    width: 100%;
    display: table;
    border-bottom: solid 0.5px rgba(131, 131, 131, 0.175);

    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
            
    &:hover {
        background-color: rgb(195, 195, 195);
  }}
`;

export const TableBody = styled.tbody`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: scroll; // ver se tem o scroll
  position: absolute; 
  background-color: #fff;
  &::-webkit-scrollbar-thumb {
                background-color: #c71b1b ;
            }

            &::-webkit-scrollbar-button {
            }

            &::-webkit-scrollbar {
                background-color: rgb(255, 255, 255);
                width           : 4px;
            }
`;


export const TableData = styled.td`
  border: none;
  font-weight: 300;
  height: 20px;
  padding: 0 10px;
  text-align: center;

  max-width: 100%;
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 18px;
    padding-top: 4px;
  }
`;


export const Button = styled.button``;